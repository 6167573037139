@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/helveticaneue.woff');
  src: url('./fonts/helveticaneue.eot?#iefix') format('embedded-opentype'),
       url('./fonts/helveticaneue.woff2') format('woff2'),
       url('./fonts/helveticaneue.woff') format('woff'),
       url('./fonts/helveticaneue.svg#Helvetica Neue') format('svg');
       font-weight: normal;
}

@font-face {
  font-family: 'Helvetica Neue bold';
  src: url('./fonts/HelveticaNeue-Bold.eot');
  src: url('./fonts/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
       url('./fonts/HelveticaNeue-Bold.woff2') format('woff2'),
       url('./fonts/HelveticaNeue-Bold.woff') format('woff'),
       url('./fonts/HelveticaNeue-Bold.svg#Helvetica Neue') format('svg');
       font-weight: bold;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-RegularItalic.eot');
  src: url('./fonts/HelveticaNeue-RegularItalic.eot?#iefix') format('embedded-opentype'),
       url('./fonts/HelveticaNeue-RegularItalic.woff2') format('woff2'),
       url('./fonts/HelveticaNeue-RegularItalic.woff') format('woff'),
       url('./fonts/HelveticaNeue-RegularItalic.svg#Helvetica Neue') format('svg');
       font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-Light.eot');
  src: url('./fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
       url('./fonts/HelveticaNeue-Light.woff2') format('woff2'),
       url('./fonts/HelveticaNeue-Light.woff') format('woff'),
       url('./fonts/HelveticaNeue-Light.svg#Helvetica Neue') format('svg');
       font-weight: 300;
}

:root {
  --body-fonts:'Helvetica Neue';
  --color-bg: black;
  --color-text: #FFFFFF;
  --red: #FF0000;
  --orange: #EE8C0F;
}
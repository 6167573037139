
.slick-slider {
    max-width: 300px !important;
    max-height: 300px !important;
    margin: auto !important;
}

.slick-dots li button:before {
    font-size: 12px !important;
    color: #D9D9D9 !important;
    opacity: 100% !important;
}

.slick-dots li.slick-active button:before {
    color: var(--orange) !important;
}

.btn__wrap {
    margin-top: 15%;
}
body, html {
  height: 100%;
  /* overflow:hidden; */
}


/* css reset */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  /* outline: 1px solid red; */
}

body {
  /* background-image: url('./assets/johnwick4movie_bg.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;  */


  background-color: var(--color-bg);
  font-family: var(--body-fonts);
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  color: var(--color-text);
}

 .background__img {
  background-image: url('./assets/JW_BG_1920x1440.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
}


button, a {
  cursor: pointer;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}

.primary__btn {
  font-style: italic;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  border-radius: 50px;
  color: white;
  padding: 20px 30px;
  background-color: var(--red);
  border: none;
  font-family: 'Helvetica Neue bold';
}

.wide__btn {
  padding: 20px 60px;
}

.primary__btn:hover {
  background-color: var(--orange);
}

.main__container {
  width: 500px;
  max-width: 100%;
  padding: 25px;
  text-align: center;
  margin-right: 12%;
  margin-left: auto;
  margin-top: 5%;

}

.main__container p {
  padding: 60px 0 24px 0;
}

.reset {
  padding-top: 25px;
}


.fadeIn {
	-webkit-animation: fade-in 1.2s ease-in both;
	        animation: fade-in 1.2s ease-in both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-2-2 17:51:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 810px) {
  .main__container {
    width: 100%;
    margin-right: none;
    margin: auto !important;
    padding-top: 25%;
  }
  .footerTT__container {
    justify-content: center;
    flex-direction: column;
    align-items: center !important;
  }
  .background__img {
    background-image: url('./assets/mobile_jw_bg.jpg');
  }
}

@media screen and (max-width: 600px){
  .main__container {
    width: 100%;
    margin-right: none;
    margin: auto !important;
    padding-top: 25%;
  }
  .treatment {
    max-width: 220px;
    margin: auto;
  }
  .main__container p {
    padding: 0px 0 24px 0;
}
.main__container .topP {
  max-width: 300px;
  margin: auto;
}
.hide {
  display: none;
}
.footerTT__container {
  justify-content: center;
}
}

@media screen and (min-width: 451px){
.final__copy-above {
  max-width: 370px;
  margin: auto;
}
}

@media screen and (max-width: 400px){
  .main__container {
    max-width: 350px;
    margin-right: none;
    margin: auto !important;
  }
}
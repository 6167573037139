textarea {
    width: 100% !important;
    background-color: #070707;
    border: 1px solid white;
    height: 117px;
    color: white;
    border-radius: 5px;
    padding: 8px 5px;
    font-size: 16px;
    font-family: var(--body-fonts);
}

::placeholder {
    color: #404040;
}

.input__container p, form {
    text-align: left;
}

.input__container p {
    padding: 30px 0 30px 0;
}

.confirm__btn {
    margin-top: 25px;
}

.back__btn-container {
    text-align: left;
}

.back__btn {
    text-decoration: underline;
    color: var(--orange);
    background: transparent;
    outline: none;
    border: none;
    text-underline-offset: 3px;
}

.btnIcon {
    margin-bottom: -3px;
    padding-right: 10px;
}

.active {
    display: flex;
    -webkit: flex;
    justify-content: center;
}

#above p {
    text-align: center;
}

.share__btns {
    display: flex;
    -webkit: flex;
    margin: auto;
    max-width: 340px;
    justify-content: center;
    align-items: center;
}

.share__btns {
    margin-top: 25px;
}

.share__btns a {
    padding: 6px;
    margin: 3px;
}

.phone__form {
    margin: auto;
    max-width: 350px;
    text-align: center !important;
}

.share__wrap input {
    height: 52px;
    font-family: 'Helvetica Neue';
    background: transparent;
    outline: none;
    border: 1px solid white;
    border-radius: 2px;
    min-width: 200px;
    color: white;
}

.txt {
    margin-top: 5px;
    margin-left: 10px;
    font-family: 'Helvetica Neue bold';
    font-style: italic;
    font-size: 18px;
    line-height: 20px;
    color: white;
    background-color: var(--orange);
    padding: 13px 14px 18px 14px;
    border-radius: 2px;
}

.smsP {
    padding: 15px 0 15px 0 !important;
    text-align: center !important;
}

.share__wrap input ::placeholder {
    padding-left: 15px;
}



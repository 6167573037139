.footerTT__container {
    display: flex;
    align-items: flex-end;
    -webkit:  flex;
    padding: 0px 30px 0px 5%;
}

.legal__btn {
    color: var(--red);
    border: solid 1px var(--red);
    padding: 5px 20px;
    background: transparent;
    font-weight: bold;
    font-family: var(--body-fonts);
}

.legal__btn:hover {
    background: red;
    color: black;
    opacity: 0.6;
    transition: 0.3s;
}

.legal__wrap {
    padding: 2% 0 3% 0;
}

.footer {
    position: fixed;
    background-color: #ffa500;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    text-align: center;
    color: black;
    font-size: 11px;
    z-index: 100;
  }

  .footer ul {
    list-style: none;
    display: flex;
    -webkit: flex;
    justify-content: right;
    -webkit-justify-content: right;
    flex-wrap: wrap;
    -webkit-justify-content: flex-end;
    align-items: flex-end;
    -webkit-align-items: flex-end;
  }

  .cc {
    font-size: 10px;
  }

  .footer__slideup {
    padding: 30px 25px;
  }

  .footer a {
    color: black;
    padding: 15px;
  }

.top {
    display: flex;
    -webkit: flex;
    text-align: left;
    width: 100%;
    padding: 10px;
}

.top .right{
    text-align: right;
    width: 100%;
}

.right {
    padding: 10px;
}

.left {
    width: 40%;
}

.bottom img {
    padding: 10px;
}

.bottom {

    padding: 10px 10px 10px 0px;
    align-items: end;
}


.closeButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
}

.btnfocus:hover {
    border: 2px solid red;
    padding: 4px 4px 0px 4px;
    opacity: 0.6;
    transition: 0.3s;
}


.copy {
    margin-top: -15px;
}

.closeBtn {
    cursor: pointer;
}



.footer__slideup.slide-in {
  transform: translateY(0);
  transition: all 0.3s ease-out;
}

.footer__slideup.slide-out {
  transform: translateY(100%);
  transition: all 0.3s ease-out;
}

@media screen and (max-width: 615px){
    .top {
        flex-direction: column; 
    }
    .top .right{
        text-align: left;
        width: 100%;
    }

    .right .buttons a:first-child {
        padding-left: 0px;
    }
    .right {
        padding-left: 0px;
    }
    .left {
        width: 100%;
    }
    .bottom img {
        padding-left: 10px;
    }
    .bottom {
        text-align: left;
    }
    .footer ul {
        display: block;
    }
    .bottom {
        flex-direction: column;
        width: 50%;
        -webkit-justify-content: flex-start;
        align-items: flex-start;
        -webkit-align-items: flex-start;
    }
}